<div class="kurz-toast">

  <div class="kurz-toast-text">
    {{ text }}
  </div>

  <aside class="kurz-toast-close-button">
    <app-icon-button
      iconType="CLOSE"
      colorTheme="BLACK"
      size="EXTRA_SMALL"
      (activateElement)="close()"
    ></app-icon-button>
  </aside>

</div>
