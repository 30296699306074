import { Component, OnInit } from '@angular/core';
import { UtilToastDefaultWrapperComponent } from '@util/services/util-toast-default-wrapper.component';

@Component({
  selector: 'app-kurz-toast-wrapper',
  templateUrl: './kurz-toast-wrapper.component.html',
  styleUrls: ['./kurz-toast-wrapper.component.scss']
})
export class KurzToastWrapperComponent extends UtilToastDefaultWrapperComponent implements OnInit {

  ngOnInit(): void {
  }

  close() {
    this.hide();
  }

}
