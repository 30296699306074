import { Injectable } from '@angular/core';
import { TranslationService } from '@spartacus/core';
import { UtilToasterService } from '@util/services/util-toaster.service';
import { filter, take } from 'rxjs/operators';
import { KurzToastWrapperComponent } from '../kurz-components/shared/kurz-toast/kurz-toast-wrapper.component';

export type ToastClassType = 'alert' | 'warning' | 'error' | 'success';

@Injectable({
  providedIn: 'root'
})
export class KurzToasterService {

  constructor(
    private utilToasterService: UtilToasterService,
    private translationService: TranslationService,
  ) {}

  toastMessage(text: string, type: ToastClassType = 'alert', duration?: number) {
    const extraClasses: string[] = [type + '-toast'];
    this.utilToasterService.toastMessage(text, null, null, KurzToastWrapperComponent, extraClasses, duration);
  }

  /**
 * Displays a translated toast message based on the provided key and options.
 *
 * @param {string} key - The translation key for the toast message.
 * @param {ToastClassType} [toastType='alert'] - The type of toast message, e.g., 'alert', 'success', etc.
 * @param {any} [translationOptions] - Additional options for translating the message.
 * @param {number} [duration] - The duration (in milliseconds) for which the toast message should be displayed.
 *
 * @returns {void}
 */
  public translatedToastMessage(key: string, toastType: ToastClassType = 'alert', translationOptions?: any, duration?: number):void {
    this.translationService.translate(key, translationOptions)
      .pipe(filter(tr => !!tr), take(1),)
      .subscribe(tr => this.toastMessage(tr, toastType, duration));
  }

}


export class KurzToasterTestingService {

  toastMessage(text: string) {}

  translatedToastMessage(text: string) {}
}
